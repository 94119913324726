.headline--large {
  color: #000000;
  font-size: 56px;
  font-family: deliverycondensed_black;
  font-weight: normal;
  text-align: center;
  text-transform: uppercase;
  letter-spacing: -1px;
  margin: 0;
  padding: 0 0 10px;
}