.toggle-input {
  margin: 0 auto;
  min-width: 120px;
  text-align: center;
  display: flex;
}

.toggle-input__value {
  color: black;
  line-height: 40px;
  font-size: 16px;
  line-height: 40px;
  text-align: right;
  text-transform: uppercase;
  letter-spacing: -0.29px;
  vertical-align: top;
  padding: 0;
}

.toggle-input__button {
  cursor: pointer;
  height: 40px;
  background: rgb(254, 204, 0);
  border: 1px solid rgb(223, 170, 75);
  box-shadow: 0px 2px 10px 0px rgba(119, 118, 115, 0.1);
  border-radius: 20px 20px 20px 20px;
  width: 98px;
  outline: none;
  position: relative;
  margin: 0 20px;
  transition: all 300ms ease;
}

.toggle-input__button:after {
  width: 24px;
  height: 24px;
  display: block;
  content: " ";
  border-radius: 50%;
  background: rgb(255, 255, 255);
  border: 1px solid rgb(184, 184, 184);
  box-shadow: 0px 1px 4px 0px rgba(119, 118, 115, 0.38);
  position: absolute;
  top: 6px;
  right: 8px;
  transition: all 300ms ease;
}
.toggle-input--active .toggle-input__button {
  background-color: rgb(255,255,255);
  border-color: rgb(184, 184, 184);
}
.toggle-input--active .toggle-input__button:after {
  right: 56px;
}

.toggle-input__value--value-1 {
  justify-content: flex-end;
}

/* col */
.toggle-input__value, 
.toggle-input__button {
  display: flex;
  flex: 1;
}

.toggle-input__button {
  flex: 0 0 92px;
}