.ec-summary {
  display: flex;
}

.ec-summary__column {
  flex: 1;
  padding: 10px;
}

.ec-summary-table th {
  text-align: left;
}
.ec-summary-table td {
  text-align: left;
}

.ec-summary-table__value {
  text-align: right;
}