.product-type--biltillbehor {
  background-image: url('../../assets/Icons/product-types/Biltillbehor.svg');
}
.product-type--datorer-tillbehor {
  background-image: url('../../assets/Icons/product-types/Datorer.svg');
}
.product-type--hemelektronik {
  background-image: url('../../assets/Icons/product-types/Hemelektronik.svg');
}
.product-type--glas-kermaik {
  background-image: url('../../assets/Icons/product-types/GlasKeramik.svg');
}
.product-type--klader {
  background-image: url('../../assets/Icons/product-types/Klader.svg');
}
.product-type--mobler {
  background-image: url('../../assets/Icons/product-types/Mobler.svg');
}
.product-type--kemikalier {
  background-image: url('../../assets/Icons/product-types/Kemikalier.svg');
}
.product-type--kosttillskott {
  background-image: url('../../assets/Icons/product-types/Kosttillskott.svg');
}
.product-type--byggmaterial {
  background-image: url('../../assets/Icons/product-types/Byggmaterial.svg');
}
.product-type--tradgard {
  background-image: url('../../assets/Icons/product-types/Tradgard.svg');
}
.product-type--vitvaror {
  background-image: url('../../assets/Icons/product-types/Vitvaror.svg');
}
.product-type--langa-varor {
  background-image: url('../../assets/Icons/product-types/LangaVaror.svg');
}
.product-type--dack {
  background-image: url('../../assets/Icons/product-types/Dack.svg');
}
.product-type--metallindustri {
  background-image: url('../../assets/Icons/product-types/Metallindustri.svg');
}
.product-type--bilindustri {
  background-image: url('../../assets/Icons/product-types/BIlindustri.svg');
}
.product-type--ovrigt {
  background-image: url('../../assets/Icons/product-types/Ovrigt.svg'); 
}
