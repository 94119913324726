.contact-card-icon {
  position: fixed;
  bottom: 80px;
  right: 20px;
  display: block;
  width: 80px;
  height: 80px;
  border-radius: 50%;
  overflow: hidden;
  cursor: pointer;
  outline: none;
  border: none;
  box-shadow: 0 2px 10px 0 rgba(119, 118, 115, 0.1);
} 
.contact-card-icon:active {
  transform: scale(.95);
}

.contact-card-icon img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  display: block;
  top: 0;
  left: 0;
  position: absolute;
}

.contact-card {
  position: fixed;
  bottom: 80px;
  right: 20px;
  border-radius: 4px;
  background: white;
  box-shadow: 0 2px 10px 0 rgba(119, 118, 115, 0.1);
  transition: opacity 300ms ease;
  font-family: deliveryregular;
  font-size: 20px;
  min-height: 150px;
  
  padding: 28px 65px 28px 160px;
}
.contact-card--hidden {
  opacity: 0;
}
.contact-card__image {
  position: absolute;
  top: 28px;
  left: 28px;
  width: 98px;
  height: 98px;
  display: block;
  border-radius: 50%;
  object-fit: cover;
}
.contact-card__name {
  font-size: 18px;
  font-family: Delivery;
  padding-bottom: 10px;
}
.contact-card__title ,
.contact-card__email ,
.contact-card__phone {
  font-size: 16px;
  font-family: deliveryregular;
  line-height: 24px;
}
