.footer-solution-item {
  cursor: pointer;
  margin-left: 22px;
  margin-right: 22px;
  width: 130px;
  text-align: center;
}

.footer-solution-item__wrapper {
  border: 3px solid transparent;
  border-radius: 50%;
  padding: 3px;
  height: 76px;
  width: 76px;
  margin: 0 auto;
}
.footer-solution-item__wrapper--current-step {
  border: 3px solid #c22a23;	
}

.footer-solution-item__bg {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #e3e3e3;
  height: 64px;
  width: 64px;
  position: relative;
  border-radius: 50%;
  transition: all 250ms ease-out;
  opacity: 0.2;
}

.footer-solution-item__bg--active {
  opacity: 1;
}

.footer-solution-item__wrapper--selected .footer-solution-item__bg {
  background-color: #ffcc00;
  opacity: 1;
}



.footer-solution-item__icon {
  height: 100px;
  height: 38px;
  width: 40px;
  background-size: contain;
  background-position: center center;
  background-repeat: no-repeat;
}

.footer-solution-item__remove {
  position: absolute;
  top: -3px;
  right: -3px;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  display: block;
  background: white;
  color: black;
  border: 1px solid #b8b8b8;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.15);
  text-align: center;
  line-height: 24px;
  opacity: 0;
}
.footer-solution-item__bg:hover .footer-solution-item__remove {
  opacity: 1;
}

.footer-solution-item__remove:hover {
  background: #c22a23;
}

.footer-solution-item__title {
  margin-top: 15px;
  margin-bottom: 15px;
  text-align: center;
  text-transform: uppercase;
  white-space: nowrap;
  position: relative;
  display: inline-block;
}

.footer-solution-item__info-link {
  position: absolute;
  top: -18px;
  right: -28px;
  border: none;

  display: block;
  overflow: hidden;
  text-indent: -1000px;

  width: 30px;
  height: 30px;

  background-color: transparent;
  background-size: 100% 100%;
  background-repeat: no-repeat;
  background-image: url('../../assets/Icons/footer/Info-Default.svg');
}
.footer-solution-item__info-link:hover {
  background-image: url('../../assets/Icons/footer/Info-Hover.svg');
}