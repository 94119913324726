.summary-card {
  width: 100%;
  margin: 0 auto;
  max-width: 1000px;
}

.summary-filters {
  display: flex;
  flex-wrap: wrap;
  width: 100%;
  padding-top: 0px;
  justify-content: center;
}

.summary-filter {
  display: block;
  width: 100px;
  height: 150px;
  position: relative;
  margin: 0 10px;
  background-repeat: no-repeat;
  background-position: top center;
}
.summary-filter__title {
  white-space: nowrap;
  position: absolute;
  bottom: 10px;
  text-align: center;
  text-transform: uppercase;
  left: 50%;
  transform: translateX(-50%);
}