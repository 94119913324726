.details-question--percentage-whole-half-pallets {
  overflow-x: hidden;
}
.thumb-slider {
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  box-shadow: 0 2px 10px 0 rgba(119, 118, 115, 0.1);
  padding: 0 20px;
  height: 21px !important;
  width: 750px;
  display: flex;
  flex-direction: row !important;
  justify-content: center;
  margin: 0 10px 50px 10px;
}

.thumb-slider-wholePallet, .thumb-slider-halfPallet {
  display: flex;
  flex-direction: row
}

.percentagePalletsWrapper {
 padding: 20px;
}

.thumb-slider-label {
  font-family: "DeliveryRegular";
  font-size: 20px;
  font-weight: normal;
  letter-spacing: -0.36px;
}

.thumb-slider-label:after {
  content: "%";
  color: rgb(184, 184, 184);
}

button {
  outline: none;
  background: white;
  padding: 0;
  border: none;

}

.sliderMinus {
  margin-right: 20px;
}

.thumbWithValue {
  cursor: pointer;
  background: rgb(255, 255, 255);
  border-radius: 6px 6px 6px 6px;
  border: 1px solid rgb(184, 184, 184);
  box-shadow: 0px 2px 14px 0px rgba(0, 0, 0, 0.22);
  height: 51px !important;
  min-width: 121px !important;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  font-family: "DeliveryRegular";
  font-size: 20px;
  font-weight: normal;
  position: absolute;
}

.sliderPlusArrow,
.sliderMinusArrow {
  cursor: pointer;
  display: inline-block;
  border-right: 2px solid rgb(17, 17, 17);
  border-bottom: 2px solid rgb(17, 17, 17);
  width: 10px;
  height: 10px;
  transform: rotate(-45deg);
}

.sliderPlusArrow {
  transform: rotate(-45deg);
  margin-left: 7px;
}

.sliderMinusArrow {
  margin-right: 7px;
  transform: rotate(-225deg);
}

.percentagePalletsHeader {
  text-align: center;
}
