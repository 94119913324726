.headline--small {
  font-size: 22px;
  font-family: Delivery;
  font-weight: bold;
  text-align: center;
  
  color: #000000;
  text-align: center;

  margin: 0;
  padding: 0 0 20px;
}