.app-footer {
  display: flex;
  z-index: 996;
  justify-content: space-between;
  flex-direction: row;
  background: rgb(194, 42, 35);
  bottom: 0;
  width: 100%;
  position: fixed;
  flex-wrap: wrap;
  padding: 6px 10px;
  align-items: center;
  box-sizing: border-box;
}
