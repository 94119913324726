@keyframes fadeInUp {
  from {
    opacity: 0;
    transform: translate3d(0, 40px, 0);
  }
  to {
    opacity: 1;
    transform: translateZ(0);
  }
}
.fadeInUp {
  animation-name: fadeInUp;
  animation-duration: 250ms;
  animation-delay: 250ms;
}
