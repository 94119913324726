.recipients-icon-industry {
  background-image: url('../../assets/Icons/recipients/Industry.svg');
}

.recipients-icon-retail {
  background-image: url('../../assets/Icons/recipients/Retail.svg');
}

.recipients-icon-ombud {
  background-image: url('../../assets/Icons/recipients/Ombud.svg');
}

.recipients-icon-home-delivery {
  background-image: url('../../assets/Icons/recipients/Hemleverans.svg');
}
