.time-range-input {
  display: flex;
  max-width: 520px;
  margin: 0 auto;
}
.time-range-input__input-wrapper {
  flex: auto;
  box-shadow: 0px 2px 10px 0px rgba(119, 118, 115, 0.1);

}
.time-range-input__divider {
  font-family: 'deliverylight';
  font-weight: normal;
  padding: 0 25px;
  flex: 0;
  line-height: 75px;
}

.react-dropdown-select-content span {
  text-align: center;
  flex: auto;
}

.react-dropdown-select-separator {
  height: 100% !important;
  opacity: 0.5;
}
.react-dropdown-select-dropdown-handle {
  margin-left: 20 px !important;
  margin-right: 15px !important;
}
.react-dropdown-select-dropdown-handle svg {
  width: 24px !important;
  height: 24px !important;
}