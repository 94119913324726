
.ec-region-active-marker {
  background-color: white;
  position: absolute;
  bottom: -17px;
  height: 130px;
  width: 170px;
  border: 1px blue solid;
  left: -1px;
  border: 1px solid #d6d6d6;
  border-width: 0 1px 0 1px;
  pointer-events: none;
}
.ec-region-active-marker__gradient {
  position: absolute;
  background: linear-gradient(180deg, rgba(255,204,0,1) 0%, rgba(255,255,255,1) 100%);
  top: 0;
  left: 0;
  width: 100%;
  height: 130px;
}

.ec-region-details {
  box-shadow: 0 2px 10px 0 rgba(119, 118, 115, 0.10);
  padding: 40px;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  margin: 10px 5px 30px;
  justify-content: flex-start;
}
.ec-country-details {
  background-image: url('../../assets/Icons/flags/finland.svg');
  background-position: left center;
  background-size: 30px 30px;
  width: 315px;
  height: 45px;

  margin: 10px 30px;
  background-repeat: no-repeat;
  position: relative;
  padding-left: 40px;
}

.ec-region-details__content { 
  position: relative;   
  display: flex;
}

.ec-region-details__close {
  position: absolute;
  top: -15px;
  right: -15px;

  width: 20px;
  height: 20px;
  line-height: 20px;
  background: #111111;
  font-size: 16px;
  text-align: center;
  color: white;
  display: block;
  cursor: pointer;
  border-radius: 50%;
  z-index: 100;
}
.ec-region-details__close:hover {
  background: #ffcc00;
}

.ec-country-details__name {
  line-height: 45px;
  font-size: 12px;
  font-family: deliveryregular;
}

.ec-country-details__fields {
  position: absolute;
  right: 0;
  top: 0;
  border: 1px blue solid;
  height: 45px;
  width: 180px;
  display: block;
  box-shadow: 0 2px 10px 0 rgba(119, 118, 115, 0.10);

  border: 1px solid #d6d6d6;
  border-radius: 4px;
  display: flex;
}

.ec-country-details__fields--hide-import {
  width: 90px;
  margin-right: 75px;
}

.ec-country-details__field {
  font-size: 14px;
  width: 50%;
  font-family: deliveryregular;
  margin: 4px;
  color: #999;
}

.ec-country-details__fields--hide-import .ec-country-details__field {
  width: 100%;
}

.ec-country-details__field--import {
  border-right: 1px #eaeaea solid;
}

.ec-country-details__field--has-value {
  background: #ffcc00;
  font-family: 'Delivery' !important;
  color: #111111;
}
.ec-country-details__column-header {
  display: flex;
  font-size: 14px;
  justify-content: space-between;
  width: 130px;
  margin-left: 190px;
}

.ec-country-details--finland { background-image: url('../../assets/Icons/flags/finland.svg') }
.ec-country-details--danmark { background-image: url('../../assets/Icons/flags/danmark.svg') }
.ec-country-details--norge { background-image: url('../../assets/Icons/flags/norge.svg') }
.ec-country-details--nederländerna { background-image: url('../../assets/Icons/flags/nederlanderna.svg') }
.ec-country-details--belgien { background-image: url('../../assets/Icons/flags/belgien.svg') }
.ec-country-details--uk { background-image: url('../../assets/Icons/flags/uk.svg') }
.ec-country-details--schweiz { background-image: url('../../assets/Icons/flags/schweiz.svg') }
.ec-country-details--frankrike { background-image: url('../../assets/Icons/flags/frankrike.svg') }
.ec-country-details--irland { background-image: url('../../assets/Icons/flags/irland.svg') }
.ec-country-details--grekland { background-image: url('../../assets/Icons/flags/grekland.svg') }
.ec-country-details--spanien { background-image: url('../../assets/Icons/flags/spanien.svg') }
.ec-country-details--portugal { background-image: url('../../assets/Icons/flags/portugal.svg') }
.ec-country-details--italien { background-image: url('../../assets/Icons/flags/italien.svg') }
.ec-country-details--tyskland { background-image: url('../../assets/Icons/flags/tyskland.svg') }
.ec-country-details--österrike { background-image: url('../../assets/Icons/flags/osterrike.svg') }
.ec-country-details--bulgarien { background-image: url('../../assets/Icons/flags/bulgarien.svg') }
.ec-country-details--rumänien { background-image: url('../../assets/Icons/flags/rumanien.svg') }
.ec-country-details--slovenien { background-image: url('../../assets/Icons/flags/slovenien.svg') }
.ec-country-details--turkiet { background-image: url('../../assets/Icons/flags/turkiet.svg') }
.ec-country-details--kroatien { background-image: url('../../assets/Icons/flags/kroatien.svg') }
.ec-country-details--serbien { background-image: url('../../assets/Icons/flags/serbien.svg') }
.ec-country-details--ungern { background-image: url('../../assets/Icons/flags/ungern.svg') }
.ec-country-details--slovakien { background-image: url('../../assets/Icons/flags/slovakien.svg') }
.ec-country-details--tjeckien { background-image: url('../../assets/Icons/flags/tjeckien.svg') }
.ec-country-details--makedonien { background-image: url('../../assets/Icons/flags/makedonien.svg') }
.ec-country-details--polen { background-image: url('../../assets/Icons/flags/polen.svg') }
.ec-country-details--estland { background-image: url('../../assets/Icons/flags/estland.svg') }
.ec-country-details--lettland { background-image: url('../../assets/Icons/flags/lettland.svg') }
.ec-country-details--litauen { background-image: url('../../assets/Icons/flags/litauen.svg') }
.ec-country-details--ukraina { background-image: url('../../assets/Icons/flags/ukraina.svg') }
.ec-country-details--vitryssland { background-image: url('../../assets/Icons/flags/vitryssland.svg') }
.ec-country-details--ryssland { background-image: url('../../assets/Icons/flags/ryssland.svg') }
.ec-country-details--luxemburg { background-image: url('../../assets/Icons/flags/luxembourg.svg') }
.ec-country-details--sverige { background-image: url('../../assets/Icons/flags/sweden.svg') }
.ec-country-details--albanien { background-image: url('../../assets/Icons/flags/albania.svg') }

.ec-map--västeuropa {
  background-image: url('../../assets/Icons/maps/vasteuropa.svg');
}
.ec-map--norden {
  background-image: url('../../assets/Icons/maps/norden.svg');
}
.ec-map--centraleuropa {
  background-image: url('../../assets/Icons/maps/centraleuropa.svg');
}
.ec-map--baltikum {
  background-image: url('../../assets/Icons/maps/baltikum.svg');
}
.ec-map--cis {
  background-image: url('../../assets/Icons/maps/cis.svg');
}