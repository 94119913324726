.summary-table {
  border-collapse: collapse;
  margin: 0 auto;
}

.summary-table th {
  text-align: left;
  width: 300px;
}

.summary-table td,
.summary-table th {
  margin: 0;
  padding: 12px 20px;
  text-align: center;
}

.summary-table td:first-of-type,
.summary-table th:first-of-type {
  width: 25%;
  text-align: left;
}

.summary-table tr {
  line-height: 30px;
}
.summary-table td {
  font-size: 20px;
  font-family: "deliveryregular";
  border-right: 1px #cbcac9 solid;
  min-width: 200px;
}

.summary-table .summary-table__title-cell {
  border-right: none;
}
.summary-table tr td:last-child {
  border-right: none;
} 

.summary-table tr:nth-child(even) {
  background: #f6f5ef;
}
.summary-table tr:nth-child(odd) {
  background: #ffffff;
}

.summary-table tr:hover {
  background: #e5e5e2;
}

.summary-table tr.summary-table__section-row-spacer {
  height: 56px;
}

.summary-table tr.summary-table__solutions-row th {
  text-align: center;
  text-transform: uppercase;
  font-size: 16px;
  white-space: nowrap;
}
.summary-table tr.summary-table__extras-row,
.summary-table tr.summary-table__solutions-row,
.summary-table tr.summary-table__section-row {
  background: transparent;
  padding: 12px 24px;
}

.summary-table tr.summary-table__section-row-data td:first-of-type,
.summary-table tr.summary-table__section-row th {
  padding: 12px 24px;
}

.summary-table tr.summary-table__extras-row {
  vertical-align: top;
  text-align: center;
}
.summary-table tr.summary-table__section-row th {
  font-size: 22px;
}

.volumeCardIcon {
  height: 100px;
  height: 38px;
  width: 50.02px;
  background-size: contain;
}

.service-point {
  background-image: url('../../assets/Icons/Medium/Produkt/Ombud.svg');
}
.dhl-paket {
  background-image: url('../../assets/Icons/Medium/Produkt/Paket.svg');
}

.volumeCardRowGrey,
.volumeCardRow {
  display: flex;
  align-items: center;
  justify-content: center;
  font-style: normal;
  height: 52px;
}

.volumeCardRowGrey {
  background: #e3e3e3;
}

.volumeCardBackground {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  background: #fecc00;
  height: 80px;
  width: 80px;
  border-radius: 50%;
}

.volumeCardBackground {
  margin: 0 auto;
  margin-bottom: 17px;
}

.summary-table__solution-link {
  cursor: pointer;
}

.summary-table .default-value {
  color: #999;
  font-family: deliverylight;
}