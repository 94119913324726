.show-more-button {
  font-family: "Delivery";
  font-weight: 600;
  border: 2px solid #fecc00;
  border-radius: 4px;
  background: #fecc00;
  padding: 10px;
  margin-top: 0;
  font-size: 16px;
  cursor: pointer;
  color: #111111;
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
  width: 100%;
  text-align: center;
  margin: 20px 0;
}

.show-more-button:before {
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #fecc00;
  transform: scaleY(0);
  transform-origin: 0 50%;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}

.show-more-button:hover,
.show-more-button:focus,
.show-more-button:active {
  outline: none;
  transition: border-color 0.3s ease-in-out;
  transition: box-shadow 0.3s ease-in-out;
  transition-delay: 50ms;
  color: #111111;
  border-color: #fecc00;
  box-shadow: 0px 2px 5px rgba(100, 100, 100, 0.3);
}

.show-more-button:hover:before,
.show-more-button:focus:before,
.show-more-button:active:before {
  transform: scaleY(1);
}

.show-more-button__text {
  margin: 0;
}
