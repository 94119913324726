.menu-button {
  color: #c22a23;
  font-family: Delivery;
  font-size: 22px;
  font-weight: bold;
  outline: none;
  border: none;
  background: transparent;
  cursor: pointer;
}
.menu-button:hover {
  color: rgb(140, 30, 24);
}

.menu {
  position: fixed;
  top: 88px;
  right: 15px;
  background: white;
  padding: 20px;
  text-align: left;
  border: 1px #ccc solid;
  border-radius: 4px;
}

.menu:after, .menu:before {
	bottom: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
}

.menu:after {
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #ffffff;
	border-width: 10px;
	margin-left: -10px;
}
.menu:before {
	border-color: rgba(255, 255, 255, 0);
	border-bottom-color: #ccc;
	border-width: 11px;
	margin-left: -11px;
}

.menu__item {
  border: none;
  display: block;
  cursor: pointer;
  padding: 4px 0;
  color: #000;
  text-decoration: none;
}
.menu__item:hover {
  color: #c22a23;
}
