.headline--medium {
  font-size: 36px;
  font-family: deliverycondensed_black;
  font-weight: normal;
  text-align: center;

  text-transform: uppercase;
  letter-spacing: -0.64px;

  margin: 0;
  padding: 0 0 20px;
}