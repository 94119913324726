
.freight-type--bag {
  background-image: url('../../assets/Icons/freight-types/Pasar.svg');
}

.freight-type--other {
  background-image: url('../../assets/Icons/freight-types/Ovrigt.svg');
}

.freight-type--paket {
  background-image: url('../../assets/Icons/freight-types/Paket.svg');
}

.freight-type--roll {
  background-image: url('../../assets/Icons/freight-types/Rullar.svg');
}
