.detailsWrapper {
  max-width: 800px;
  margin: 0 auto;
}

.productsValue {
  position: relative;
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.productValue {
  text-transform: uppercase;
  margin-top: auto;
  width: 126px;
  border-radius: 4px;
  border: 1px solid #b8b8b8;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  bottom: 0;
  margin-left: 15px;
  margin-right: 15px;
}
.productValue--selected {
  background-color: #fecc00;
}

.productValue:hover {
  cursor: pointer;
  background-color: #fecc00;
}

.low {
  height: 72px;
}

.medium {
  height: 128px;
}

.high {
  height: 228px;
}
