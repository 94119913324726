.extra-product {
  width: 200px;
  height: 120px;
  padding: 10px;
  margin-bottom: 15px;
  
  outline: none;
  position: relative;
  cursor: pointer;
  
  background: white;
  border: 1px solid #d6d6d6;
  border-radius: 4px;

  box-shadow: 0 2px 10px 0 rgba(119, 118, 115, 0.10);
  transition: all 300ms ease;
}
.extra-product:hover {
  box-shadow: 0 2px 20px 0 rgba(119, 118, 115, .3);
  transform: scale(1.02); 
}

.extra-product--selected {
  background-color: #ffcc00;
}

.extra-product__title {
  font-size: 14px;
  font-weight: bold;
  letter-spacing: -0.25px;
  text-align: center;
  text-transform: uppercase;
  position: absolute;
  width: 100%;  
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  padding: 0 10px;
}

.extra-product__plus-icon {
  position: absolute;
  top: 8px;
  right: 8px;

  height: 28px;
  width: 28px;

  color: #111;
  font-size: 24px;
  font-family: 'deliveryregular';

  text-align: center;
  vertical-align: baseline;
  line-height: 25px;
}
