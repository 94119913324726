.slider {
  border-radius: 4px;
  border: 1px solid #e3e3e3;
  box-shadow: 0 2px 10px 0 rgba(119, 118, 115, 0.1);
  padding: 0 20px;
  display: flex;
  font-family: "deliveryregular";
  height: 72px !important;
  flex-direction: column;
  align-items: center;
}


input {
  outline: none;
  border: white;
  text-align: right;
}

input[type=number]::-webkit-inner-spin-button, 
input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

.plusMinusWrapper {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  margin-top: 20px;
}

.number-of-packages,
.average-weight,
input {
  color: #000000;
  font-size: 28px;
  font-weight: normal;
  letter-spacing: -0.51px;
  text-align: center;
}

.number-of-packages-st,
.average-weight-kg {
  color: #b8b8b8;
  font-size: 28px;
  letter-spacing: -0.51px;
  font-weight: normal;
}

.sliderPlus,
.sliderMinus {
  cursor: pointer;
  background: rgb(194, 42, 35);
  width: 21px;
  height: 2px;
  position: relative;
}

.sliderPlus {
  left: 0;
}

.sliderPlus::before {
  background: rgb(194, 42, 35);
  content: "";
  width: 2px;
  height: 20px;
  position: absolute;
  margin-top: -9px;
  margin-right: 9.5px;
}

.sliderMinus {
  margin-right: 20px;
}

.sliderPlusArrow,
.sliderMinusArrow {
  cursor: pointer;
  display: inline-block;
  border-right: 2px solid rgb(17, 17, 17);
  border-bottom: 2px solid rgb(17, 17, 17);
  width: 10px;
  height: 10px;
  transform: rotate(-45deg);
}

.sliderPlusArrow {
  transform: rotate(-45deg);
  margin-left: 7px;
}

.sliderMinusArrow {
  margin-right: 7px;
  transform: rotate(-225deg);
}
