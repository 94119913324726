body {
  background-image: none;
}

.volumeCardIcon {
  height: 100px;
  height: 38px;
  width: 50.02px;
  background-size: contain;
}

.service-point {
  background-image: url('../../assets/Icons/Medium/Produkt/Ombud.svg');
}

.parcel-connect {
  background-image: url('../../assets/Icons/Medium/Produkt/Ombud.svg');
}

.dhl-paket {
  background-image: url('../../assets/Icons/paket.svg');
}

.paket-export {
  background-image: url('../../assets/Icons/Medium/Produkt/Paket.svg');
}

.dhl-pall {
  background-image: url('../../assets/Icons/Medium/Produkt/Pall.svg');
}

.dhl-stycke {
  background-image: url('../../assets/Icons/Medium/Produkt/Pall Copy 1.svg');
}

.home-delivery {
  background-image: url('../../assets/Icons/Medium/Produkt/Hemleverans.svg');
}

.dhl-parti {
  background-image: url('../../assets/Icons/Medium/Produkt/Parti.svg');
}

.euroconnect {
  background-image: url('../../assets/Icons/Medium/Produkt/Euroconnect.svg');
}
