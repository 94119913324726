.market-icon-domestic {
  background-image: url('../../assets/Icons/markets/Inrikes.svg');
}

.market-icon-international-EU {
  background-image: url('../../assets/Icons/markets/Europa.svg');
}

.market-icon-international-norden {
  background-image: url('../../assets/Icons/markets/Norden.svg');
}
