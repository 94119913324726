.freetext-info-input {
  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.29);
  background: #ffffff;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  width: 100%;
  max-width: 650px;
  display: block;
  margin: 0 auto;
  padding: 24px;
  outline: none;
  min-height: 200px;
  font-family: deliveryregular;
  line-height: 1.5;
  resize: vertical;

}