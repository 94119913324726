.details-section--volume .details-question--number-of-packages,
.details-section--volume .details-question--average-weight {
  margin-bottom: 40px;
  padding: 0 10px;
  box-sizing: border-box;
}

.details-question--average-weight,
.details-question--number-of-packages,
.details-question--number-of-shipments {
  width: 50%;
  display: inline-block;
}
