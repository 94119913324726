header {
  height: 72px;
  z-index: 999;
  background: linear-gradient(
    90deg,
    rgb(254, 204, 0) 0%,
    rgb(249, 235, 178) 100%
  );
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  position: fixed;
  flex-wrap: wrap;
  overflow: hidden !important;

  padding: 0 50px;
}

.logo {
  background-image: url('../../assets/Icons/DHLLogo.svg');
  height: 24px;
  width: 168px;
}
