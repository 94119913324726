.text-input {
  text-align: center;
  font-family: deliveryregular;
  color: #333;
  font-size: 24px;
  font-weight: normal;
  letter-spacing: -0.51px;
  padding: 14px;
  outline: none;

  background: transparent;
  width: auto;
}

.text-input::placeholder {
  color: #b8b8b8;
}

.text-input-wrapper {
  width: 100%;
  max-width: 450px;
  position: relative;
  border: 1px red solid;
  display: flex;
  box-shadow: inset 0 2px 3px 0 rgba(0, 0, 0, 0.29);
  background: #ffffff;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  justify-content: center;
  margin: 0 auto 20px;
}

.text-input__suffix {
  font-family: deliveryregular;
  font-size: 24px;
  color: #b8b8b8;
  margin: 0 10px;
  line-height: 57px;
  text-align: left;
}