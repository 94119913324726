.footer-solutions-wrapper {
  box-shadow: 0px 2px 10px 0px rgba(0, 0, 0, 0.13);
  border-radius: 4px;
  background: white;
  position: fixed;
  bottom: 30px;
  left: 50%;
  transform: translateX(-50%) scale(0.65);
  z-index: 999;
  padding-top: 15px;
  transition: transform 150ms ease-out;
}
/* .footer-solutions-wrapper:hover {
  transform: translateX(-50%) scale(0.95);
} */

.footer-solutions-wrapper:after {
  top: 100%;
	left: 50%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-color: rgba(255, 255, 255, 0);
	border-top-color: #ffffff;
	border-width: 10px;
  margin-left: -10px;
  transform: translate(0, -1px);
}


.footer-solutions {
  justify-content: center;
  display: flex;
  flex-direction: row;
  overflow: visible;
}
