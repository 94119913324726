.shipping-items-icon-paket {
  background-image: url('../../assets/Icons/shipping-items/Paket.svg');
}

.shipping-items-icon-pall-1-3 {
  background-image: url('../../assets/Icons/shipping-items/Pall.svg');
}

.shipping-items-icon-pall-3 {
  background-image: url('../../assets/Icons/shipping-items/Pall Multiple.svg');
}