.step {
  display: block;
  text-decoration: none;
}
.step:hover {
  color: white;
  text-decoration: underline;
}
.stepWrapper {
  display: flex;
  flex-direction: row;
}

.step,
.step-text {
  display: flex;
  align-items: center;
  position: relative;
}

.step {
  min-width: 160px;
}

.step-circle {
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
  border: 2px solid #ffffff;
  height: 28px;
  width: 28px;
  border-radius: 50%;
  font-size: 12px;
}
.step-circle--active {
  background: white;
  color: rgb(194, 42, 35);
}

.step-text {
  color: white;
  padding-left: 10px;
}

.stepWrapper > *:last-child .step-text {
  margin-right: 0;
}
/* .step-number-of-products {
  background-color: #fecc00;
  border: 2px solid #c22a23;
  font-size: 10px;
  position: absolute;
  top: -2px;
  left: 22px;
  color: #000000;
  text-align: center;
  font-family: Delivery;
  width: 18px;
  height: 18px;
  line-height: 18px;
  border-radius: 18px;
} */
