.ec-summarys {
  width: 900px;
  margin: 50px auto 0;
}

.ec-summary {
  padding-bottom: 80px;
}

.ec-summarys table {
  border-collapse: collapse;
  width: 100%;
  font-size: 16px;
  page-break-inside: avoid;
}

.ec-summarys th {
  text-align: left;
  width: 120px;
  vertical-align: top;
  font-family: deliveryregular;
  padding: 5px 0 5px;
}

.ec-summarys thead th {
  font-family: 'Delivery';
  font-weight: normal;
  padding-top: 30px;
  padding-bottom: 10px;
}
.ec-summarys td {
  text-align: right;
  padding: 5px 0 5px;

}
.ec-summarys tr {
  border-bottom: 1px solid rgb(184, 184, 184);
}

.ec-summarys .default-value {
  font-family: deliverylight;
  color: #999;
}
