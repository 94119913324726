::-webkit-scrollbar {
  width: 12px;
}

/* Track */
::-webkit-scrollbar-track {
  background: #fafafa;
}
 
/* Handle */
::-webkit-scrollbar-thumb {
  background: rgb(131, 28, 23);
}

/* Handle on hover */
::-webkit-scrollbar-thumb:hover {
  background: rgb(89, 20, 16); 
}
