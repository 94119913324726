@font-face {
  font-family: 'Delivery';
  src: url('../assets/Fonts/delivery_bd-webfont.woff2') format('woff2'),
    url('../assets/Fonts/delivery_bd-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'deliverybold_italic';
  src: url('../assets/Fonts/delivery_bdit-webfont.woff2') format('woff2'),
    url('../assets/Fonts/delivery_bdit-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'deliverycondensed_black';
  src: url('../assets/Fonts/delivery_cdblk-webfont.woff2') format('woff2'),
    url('../assets/Fonts/delivery_cdblk-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'deliverycondensed_light';
  src: url('../assets/Fonts/delivery_cdlt-webfont.woff2') format('woff2'),
    url('../assets/Fonts/delivery_cdlt-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'deliveryitalic';
  src: url('../assets/Fonts/delivery_it-webfont.woff2') format('woff2'),
    url('../assets/Fonts/delivery_it-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'deliverylight';
  src: url('../assets/Fonts/delivery_lt-webfont.woff2') format('woff2'),
    url('../assets/Fonts/delivery_lt-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'deliverylight_italic';
  src: url('../assets/Fonts/delivery_ltit-webfont.woff2') format('woff2'),
    url('../assets/Fonts/delivery_ltit-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'deliveryregular';
  src: url('../assets/Fonts/delivery_rg-webfont.woff2') format('woff2'),
    url('../assets/Fonts/delivery_rg-webfont.woff') format('woff');
  font-weight: normal;
  font-style: normal;
}