* {
  box-sizing: border-box;
}
body {
  margin: 0;
  font-family: 'Delivery';
  background-size: cover;
  height: 100%;
}

.content {
  padding-top: 72px;
  padding-bottom: 68px;
  min-width: 1200px;
}

.container {
  width: 96%;
  margin: 0 auto;
  max-width: 1320px;
}

hr {
  height: 1px;
  background-color: #e3e3e3;
  border: none;
  width: 100%;
}


.regular-text {
  font-family: 'deliveryregular';
  font-weight: normal;
}
.text--center {
  text-align: center;
}