.option-card {
  cursor: pointer;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  width: 55%;
  box-shadow: 0 2px 10px 0 rgba(119, 118, 115, 0.10);
  width: 182px;
  height: 242px;
  margin: 5px;
  background: #ffffff;
  color: #111111;
  transform: perspective(1px) translateZ(0);
  position: relative;
  border: 1px solid #d6d6d6;
  border-radius: 4px;
  transition: all 300ms ease;
}

.option-card:hover {
  box-shadow: 0 2px 20px 0 rgba(119, 118, 115, .3);
  transform: scale(1.02);
}

.option-card__hover {
  background-repeat: no-repeat;
  background-position: center;

  width: 182px;
  height: 242px;
  border-radius: 4px;

  background-position: 50% 30%;
}

.option-card__hover--active {
  background-color: #ffcc00;
}

.option-card__text {
  position: absolute;
  width: 100%;
  bottom: 25px;
  text-align: center;
  text-transform: uppercase;
}
