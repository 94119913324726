.background-image {
  background-image: url('../../assets/Background.png');
  background-size: cover;
  position: fixed;
  left: 0;
  top: 0;
  height: 100vh;
  width: 100vw;
  z-index: -1;
}

.background-image-wrapper {
  position: absolute;
  top: 0;
  padding-top: 100px;
  min-height: calc(100vh + 120px);
  width: 100%;

  padding-bottom: 100px;
}