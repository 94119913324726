.red-button {
  box-shadow: 0px 0px 0px #a90700;
  font-family: "Delivery";
  font-weight: 600;
  border: none;
  border-radius: 4px;
  background-color: #c22a23;
  padding: 13px 30px;
  font-size: 16px;
  cursor: pointer;
  color: #ffffff;
  display: inline-block;
  vertical-align: middle;
  transform: perspective(1px) translateZ(0);
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
  width: 100%;
  margin: 0;
}

.red-button:before {
  box-shadow: 0px 2px 5px rgba(100, 100, 100, 0);
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #a90700;
  border-radius: 4px;
  transform: scaleY(0);
  transform-origin: 0 50%;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.red-button:hover,
.red-button:focus,
.red-button:active {
  outline: none;
  color: #ffffff;
  border-radius: 4px;
  box-shadow: 0px 2px 5px rgba(100, 100, 100, 0.5);
  transition: box-shadow 0.3s ease-in-out;
  transition-delay: 50ms;
}
.red-button:hover:before,
.red-button:focus:before,
.red-button:active:before {
  border-radius: 4px;
  transform: scaleY(1);
}
