.goBackButton {
  color: #ffffff;
  display: flex;
  align-items: center;
  text-align: center;
  justify-content: center;

  border-radius: 4px;
  border: 2px solid #ffffff;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.07);
  height: 40px;
  width: 236px;
  background-color: #c22a23;
  font-size: 16px;
  cursor: pointer;
  transform: perspective(1px) translateZ(0);
  position: relative;
  transition-property: color;
  transition-duration: 0.3s;
}

.goBackButton:before {
  box-shadow: 0px 2px 5px rgba(100, 100, 100, 0);
  content: "";
  position: absolute;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background: #ffffff;
  color: #a90700;
  border-radius: 4px;
  transform: scaleY(0);
  transform-origin: 0 50%;
  transition-property: transform;
  transition-duration: 0.3s;
  transition-timing-function: ease-out;
}
.goBackButton:hover,
.goBackButton:focus,
.goBackButton:active {
  outline: none;
  color: #a90700;
  border-radius: 4px;
  box-shadow: 0px 2px 5px rgba(100, 100, 100, 0.5);
  transition: box-shadow 0.3s ease-in-out;
  transition-delay: 50ms;
}
.goBackButton:hover:before,
.goBackButton:focus:before,
.goBackButton:active:before {
  border-radius: 4px;
  transform: scaleY(1);
}
