.product-extras {
  max-width: 650px;
  width: 100%;
  margin: 0 auto;
}

.product-extras__grid {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}