.extraInfoInputWrapper {
  position: absolute;
  cursor: pointer;
  display: flex;
  height: 32px;
  width: 176px;
  right: 0;
  left: 0;
  margin-left: auto;
  margin-right: auto;
  background: rgb(255, 255, 255);
  border-radius: 0px 0px 3px 3px;
  display: flex;
  flex-direction: row;
  align-items: center;
  bottom: 1px;
}

.extraInfoInputText {
  color: rgb(0, 0, 0);
  cursor: pointer;
  font-family: "DeliveryRegular";
  font-size: 12px;
  padding: 10px;
  font-weight: normal;
  height: 21px;
  letter-spacing: -0.4px;
  width: 133px;
}

.checkbox-label input {
  position: absolute;
  cursor: pointer;
  opacity: 0;
  right: 2px;
  width: 200px;
  height: 60px;
  z-index: 999;
}

.checkbox-label .checkbox-custom {
  position: absolute;
  width: 24px;
  right: 3px;
  bottom: 3px;
  height: 24px;
  width: 24px;
  background-color: white;
  border-radius: 5px;
  transition: all 0.3s ease-out;
  border: 2px solid rgb(161, 161, 161);
}

.checkbox-label input:checked ~ .checkbox-custom {
  background-color: #ffffff;
  border-radius: 5px;
  transform: rotate(0deg) scale(1);
  opacity: 1;
  border: 2px solid rgb(161, 161, 161);
}

.checkbox-label .checkbox-custom::after {
  position: absolute;
  content: "";
  margin-bottom: 10px;
  height: 0px;
  width: 0px;
  border-radius: 5px;
  border-width: 0 3px 3px 0;
  transform: rotate(0deg) scale(0);
  opacity: 1;
  transition: all 0.3s ease-out;
}

.checkbox-label input:checked ~ .checkbox-custom::after {
  transform: rotate(45deg) scale(1);
  opacity: 1;
  position: absolute;
  margin-left: 7px;
  margin-top: 3px;
  width: 4px;
  height: 8px;
  border: solid black;
  border-width: 0 2px 2px 0;
  background-color: transparent;
  border-radius: 0;
}
