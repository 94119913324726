/* 
Good print CSS reference
https://www.jotform.com/blog/css-perfect-print-stylesheet-98272/ */

/* TODO: Move everything into @media print { once done with testing */
.summary-view--print { display: none; }
@media print {
  
  @page {
    margin: 0;
  }
  .summary-view,
  .app-header,
  .app-footer,
  .contact-card-icon {
    display: none !important;
  }

  .content {
    min-width: 0;
    padding: 0;
  }

  .print-header {
    background: linear-gradient(
      90deg,
      rgb(254, 204, 0) 0%,
      rgb(249, 235, 178) 100%
    );
    padding: 15px 20px;
    -webkit-print-color-adjust: exact; 
    margin-bottom: 80px;
  }

  .print-page {
    margin: 1cm;
    padding: 0.5cm 2.5cm;
    page-break-inside: avoid;
  }

  .summary-view--print {
    display: block !important;
    color: black;
  }

  .print-headline {
    font-size: 24px;
    font-family: 'deliverycondensed_black';
    font-weight: normal;
    text-transform: uppercase;
    letter-spacing: -0.64px;
  }

  .print-headline--large {
    font-size: 40px;
    text-align: center;
    letter-spacing: -1px;
  }

  .print-paragraph {
    font-size: 20px;
    line-height: 1.5;
    font-family: "deliveryregular";
    font-weight: normal; 
    margin: 0;
  }

  .print-user-card {
    margin-top: 30px; 
  }

  .print-user-card__company-name {
    font-size: 18px;
    font-family: 'Delivery';
    font-weight: normal;
    padding-bottom: 10px;
    border-bottom: 1px solid rgb(184, 184, 184);
    margin-bottom: 10px;
  }
  .print-user-card__name {
    font-size: 16px;
    font-family: 'Delivery';
    font-weight: normal;  
    padding-bottom: 10px;
  }
  .print-user-card__title ,
  .print-user-card__email ,
  .print-user-card__phone {
    font-size: 14px;
    line-height: 24px;
    font-family: 'deliveryregular';
    font-weight: normal;
  }

  .print-section {
  }

  .print-details {
    margin-top: 20px;
  }

  .print-definition-list__term ,
  .print-definition-list__definition {
    font-size: 16px;
    line-height: 2;
    margin: 0;
    border-bottom: 1px solid rgb(184, 184, 184);
  }

  .print-definition-list__term {
    font-family: 'Delivery';
    font-weight: normal;
  }
  .print-definition-list__definition {
    font-family: "deliveryregular";
    font-weight: normal;
  }

  .print-product-sheet {
    font-family: "deliveryregular";
    font-weight: normal;
    width: 100%;
    margin: 0;
    padding: 0;
    float: left;
    page-break-inside: avoid;
  }
  .print-product-sheet:nth-child(even) {
    clear: left;
  }

  .print-product-sheet table {
    border-collapse: collapse;
    width: 100%;
    font-size: 16px;
    page-break-inside: avoid;
  }
  
  .print-product-sheet th {
    text-align: left;
    width: 120px;
    vertical-align: top;
    font-family: deliveryregular;
    padding: 5px 0 5px;
  }

  .print-product-sheet thead th {
    font-family: 'Delivery';
    font-weight: normal;
    padding-top: 30px;
    padding-bottom: 10px;
  }
  .print-product-sheet td {
    text-align: right;
    padding: 5px 0 5px;

  }
  .print-product-sheet tr {
    border-bottom: 1px solid rgb(184, 184, 184);
  }

  .default-value {
    font-family: deliverylight;
    color: #999;
  }
}